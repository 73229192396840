.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Set the height of the container to viewport height to center the content vertically */
}

.main-image {
  width: 65%; /* Adjust the width of the image as needed */
  height: auto; /* Maintain aspect ratio */
  position: absolute;
  top: 50%; /* Place the image at the center vertically */
  left: 50%; /* Place the image at the center horizontally */
  transform: translate(-50%, -50%); /* Center the image precisely */
  z-index: -1000;
}
  
  .cybr-btn {
    --primary: #4b128b; 
    --shadow-primary: #59CBE8; 
    --primary-hue: 0;
    --primary-lightness: 50;
    --color: hsl(0, 0%, 100%);
    --font-size: 26px;
    --shadow-primary-hue: 180;
    --label-size: 9px;
    --shadow-secondary-hue: 60;
    --shadow-secondary: hsl(var(--shadow-secondary-hue), 90%, 60%);
    --clip: polygon(0 0, 100% 0, 100% 100%, 8% 100%, 0 100%);
    --border: 4px;
    --shimmy-distance: 5;
    --clip-one: polygon(0 2%, 100% 2%, 100% 95%, 95% 95%, 95% 90%, 85% 90%, 85% 95%, 8% 95%, 0 70%);
    --clip-two: polygon(0 78%, 100% 78%, 100% 100%, 95% 100%, 95% 90%, 85% 90%, 85% 100%, 8% 100%, 0 78%);
    --clip-three: polygon(0 44%, 100% 44%, 100% 54%, 95% 54%, 95% 54%, 85% 54%, 85% 54%, 8% 54%, 0 54%);
    --clip-four: polygon(0 0, 100% 0, 100% 0, 95% 0, 95% 0, 85% 0, 85% 0, 8% 0, 0 0);
    --clip-five: polygon(0 0, 100% 0, 100% 0, 95% 0, 95% 0, 85% 0, 85% 0, 8% 0, 0 0);
    --clip-six: polygon(0 40%, 100% 40%, 100% 85%, 95% 85%, 95% 85%, 85% 85%, 85% 85%, 8% 85%, 0 70%);
    --clip-seven: polygon(0 63%, 100% 63%, 100% 80%, 95% 80%, 95% 80%, 85% 80%, 85% 80%, 8% 80%, 0 70%);
    font-family: "Audiowide", sans-serif;
    font-style: normal;
    color: var(--color); /* button text color */
    cursor: pointer;
    background: transparent;
    text-transform: uppercase;
    font-size: var(--font-size);
    outline: transparent;
    letter-spacing: 2px;
    position: absolute;
    font-weight: 700;
    border: 0;
    min-width: 300px;
    height: 75px;
    line-height: 75px;
    transition: background 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .cybr-btn:hover {
    --primary: hsl(268, 91%, 25%);
  }
  
  .cybr-btn:after,
  .cybr-btn:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    clip-path: var(--clip);
    z-index: -1;
  }
  
  .cybr-btn:before {
    background: var(--shadow-primary);
    transform: translate(var(--border), 0);
  }
  
  .cybr-btn:after {
    background: var(--primary);
  }
  
  .cybr-btn__tag {
    position: absolute;
    padding: 1px 4px;
    letter-spacing: 1px;
    line-height: 1;
    bottom: -5%;
    right: 5%;
    font-weight: normal;
    color: hsl(0, 0%, 0%);
    font-size: var(--label-size);
  }
  
  .cybr-btn__glitch {
    position: absolute;
    top: calc(var(--border) * -1);
    left: calc(var(--border) * -1);
    right: calc(var(--border) * -1);
    bottom: calc(var(--border) * -1);
    background: var(--shadow-primary);
    text-shadow: 2px 2px var(--shadow-primary), -2px -2px var(--shadow-secondary);
    clip-path: var(--clip);
    animation: glitch 2s infinite;
    display: none;
  }
  
  .cybr-btn:hover .cybr-btn__glitch {
    display: block;
  }
  
  .cybr-btn__glitch:before {
    content: '';
    position: absolute;
    top: calc(var(--border) * 1);
    right: calc(var(--border) * 1);
    bottom: calc(var(--border) * 1);
    left: calc(var(--border) * 1);
    clip-path: var(--clip);
    background: var(--primary);
    z-index: -1;
  }
  
  @keyframes glitch {
    0% {
      clip-path: var(--clip-one);
    }
    2%, 8% {
      clip-path: var(--clip-two);
      transform: translate(calc(var(--shimmy-distance) * -1%), 0);
    }
    6% {
      clip-path: var(--clip-two);
      transform: translate(calc(var(--shimmy-distance) * 1%), 0);
    }
    9% {
      clip-path: var(--clip-two);
      transform: translate(0, 0);
    }
    10% {
      clip-path: var(--clip-three);
      transform: translate(calc(var(--shimmy-distance) * 1%), 0);
    }
    13% {
      clip-path: var(--clip-three);
      transform: translate(0, 0);
    }
    14%, 21% {
      clip-path: var(--clip-four);
      transform: translate(calc(var(--shimmy-distance) * 1%), 0);
    }
    25% {
      clip-path: var(--clip-five);
      transform: translate(calc(var(--shimmy-distance) * 1%), 0);
    }
    30% {
      clip-path: var(--clip-five);
      transform: translate(calc(var(--shimmy-distance) * -1%), 0);
    }
    35%, 45% {
      clip-path: var(--clip-six);
      transform: translate(calc(var(--shimmy-distance) * -1%));
    }
    40% {
      clip-path: var(--clip-six);
      transform: translate(calc(var(--shimmy-distance) * 1%));
    }
    50% {
      clip-path: var(--clip-six);
      transform: translate(0, 0);
    }
    55% {
      clip-path: var(--clip-seven);
      transform: translate(calc(var(--shimmy-distance) * 1%), 0);
    }
    60% {
      clip-path: var(--clip-seven);
      transform: translate(0, 0);
    }
    31%, 61%, 100% {
      clip-path: var(--clip-four);
    }
  }
  
  .cybr-btn:nth-of-type(2) {
    --primary-hue: 260;
  }

  .button-1 {
    top: calc(68% - 75px); /* Adjust the distance from the top */
    left: calc(0% - 400px); /* Adjust the distance from the left */
}

  .button-2 {
      top: calc(35% - 25px); /* Adjust the distance from the top */
      left: calc(0% - 335px); /* Adjust the distance from the left */
  }

  .button-3 {
      top: calc(0% + 55px); /* Adjust the distance from the top */
      left: calc(50% - 150px); /* Adjust the distance from the left */
  }

  .button-4 {
      top: calc(35% - 25px); /* Adjust the distance from the top */
      left: calc(100% + 40px); /* Adjust the distance from the left */
  }

  .button-5 {
    top: calc(68% - 75px); /* Adjust the distance from the top */
    left: calc(100% + 95px); /* Adjust the distance from the left */
}

/* Mobile Styles */
@media only screen and (max-width: 640px) {
  .container {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    padding: 5vw; /* Add padding for spacing */
    overflow-x: scroll;
  }

  .main-image {
    width: 50%;
    position: relative;
    top: 0;
    margin-top: 15vh;
    margin-right: 45vw;
  }

  .resume-btn-container {
    margin-top: 10px; /* Adjust margin top for spacing */
    margin-bottom: 20px; /* Adjust margin bottom for spacing */
  }

  .cybr-btn {
    width: 100%; /* Set button width to fill container */
    margin-bottom: 4vh; /* Add margin between buttons */
    left: auto; /* Reset left positioning */
  }

  .button-3 {
    top: calc(0% + 490px); 
  }

  .button-2 {
    top: calc(0% + 575px); 
  }

  .button-4 {
    top: calc(0% + 660px); 
  }

  .button-5 {
    top: calc(0% + 745px); 
  }

  .button-1{
    top: calc(0% + 830px); 
  }
}
