* {
  box-sizing: border-box;
  /* Style the scrollbar */
  scrollbar-width: thin; /* "auto" or "thin" depending on browser support */
  scrollbar-color: #4b128b #201547; /* Track and thumb color */
}

body {
  /* overflow: hidden; */ /* Remove this line to allow scroll bars */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  font-family: 'Cyber', sans-serif;
  background: #361566;
}

h1 {
  font-family: "Audiowide", sans-serif;
}

h2 {
  color: #59CBE8;
  margin: 0%;
  text-transform: uppercase;
  font-family: "Audiowide", sans-serif;
  font-style: normal;
  font-size: 20px;
}

p {
  font-family: "Anta", sans-serif;
}

a {
  font-family: "Audiowide", sans-serif;
  text-decoration: none;
  color: #59CBE8;
  display: flex;
  flex-direction: row;
}

/* Mobile Styles */
@media only screen and (max-width: 600px) {
  h2 {
    font-size: 16px; /* Decrease font size for smaller screens */
  }
  
  p {
    font-size: 14px; /* Decrease font size for smaller screens */
  }
  
  a {
    font-size: 14px; /* Decrease font size for smaller screens */
  }
}