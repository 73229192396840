.modal--mask {
  background-color: rgba(40, 27, 87, 0.6);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9998;
  animation: fadeIn 0.5s cubic-bezier(0.165, 0.840, 0.440, 1.000);
}

.modal-window {
  background-color: #201547;
  box-shadow: 0 0 6px rgba(32, 21, 71, 0.8);
  width: 40%;
  height: 73%;

  overflow-x: auto;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
 
.modal--body {
  font-size: 20px;
  color: #ffffff;
  margin: 1rem;
  height: 80%;
} 

.modal--body h1{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #E93CAC;
  text-transform: uppercase;
}

.modal--body button {
  cursor: pointer;
  background: transparent;
  text-transform: initial;
  font-weight: 800;
  border: 0;

  color: #E93CAC;
  font-size: 20px;
}

@media screen and (max-width: 767px) {
  .modal-window {
    width: 100%;
    height: 100%;
  }
}