.contact-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  
  .contact-form {
    width: 100%; /* Adjust width as needed */
    max-width: 600px; /* Adjust max-width as needed */
    padding: 20px; /* Add padding as needed */ /* Set background color */
    border-radius: 10px; /* Add border radius */
  }
  
  .contact-form input[type="text"],
  .contact-form input[type="email"],
  .contact-form textarea {
    width: calc(100% - 20px); /* Adjust width to account for padding */
    padding: 10px;
    margin-bottom: 3px;
    border: 1px solid #00BCE1;
    border-radius: 5px;
    background-color: #051C2C; /* Set background color */
    color: #00BCE1; /* Set text color */
    outline-color: #59CBE8;
    outline-width: 1px; /* Optional: Adjust outline width */
    outline-style: solid;
  }
  
  .contact-form textarea {
    resize: none; /* Does not allow vertical resizing */
    overflow-y: hidden;
  }
  
  .contact-form button[type="submit"] {
    padding: 12px 20px;
    margin-top: 25px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    font-size: 30px;
    align-items: center;

    font-family: "Audiowide", sans-serif;
    font-style: normal;
  }
  
  .contact-form button[type="submit"]:hover { 
    color: #ffffff;
  }

  ::placeholder {
    color: #00BCE1;
  }
  