.preloader{
    height: 100%;
    width: 100%;
    background: #051C2C;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 55;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.preloader .texts-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 400px;
    overflow: hidden;
    color: #00BCE1;

    font-family: "Audiowide", sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 25px;

}