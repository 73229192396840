.about-image {
    float: right;
    width: 50%; 
    max-width: 300px; 
    border: double 4px transparent;
    border-radius: 20%;
    background-image: linear-gradient(white, white), radial-gradient(circle at top left, #59CBE8,#1E22AA);
    background-origin: border-box;
    background-clip: padding-box, border-box;
}

.about-icons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 15%;
    margin: 0 5%;
}