.resume-btn-container{
  margin-top: 630px;
}

.resume-btn {
    cursor: pointer;
    z-index: 10;
    padding-top: 5px;
    margin-left: 1vw;

    font-family: "Audiowide", sans-serif;
    font-weight: 375;
    font-style: normal;
    background: transparent;
    text-transform: uppercase;
    font-weight: 800;
    border: 0;

    color: #E93CAC;
    letter-spacing: 7px;
    font-size: 40px;

    align-items: center;
  } 

  .resume-btn:hover {
    color: #ffffff;
  }

  /* Mobile Styles */
  @media only screen and (max-width: 600px) {
    .resume-btn {
      position: relative; /* Ensure the button is not absolutely positioned */
      margin-bottom: 450px; /* Add margin between buttons */
      left: auto; /* Reset left positioning */
      top: 0; /* Reset top positioning */
    }

    a {
      margin: 0;
      padding: 0;
    }
  }